<template>
	<div id="userList" class="el-content">
		<div class="tips">
			<p style="color: #E74C3C;">温馨提示：员工登录请使用 “(用户名/手机号) @{{ master_name }}” 进行登录</p>
		</div>
		<a-space class="mb12">
			<a-input-search v-model:value="name" style="width: 300px" placeholder="姓名" enter-button @search="getUserList(1, info.limit)" />
			<a-button v-has="{ action: '/admin/employee/employee_info' }" @click="show.addOrEdit = true">
				<i class="ri-add-line ri-top"></i>
				添加员工
			</a-button>
		</a-space>
		
		<a-table :pagination="false" row-key="user_id" :data-source="info.list" :columns="[
			{title:'ID',dataIndex:'user_id'},
			{title:'手机号',dataIndex:'mobile', slots: { customRender: 'mobile' }},
			{title:'用户名',dataIndex:'name', slots: { customRender: 'name' }},
			{title:'状态',dataIndex:'status', slots: { customRender: 'status' }},
			{title:'创建时间',dataIndex:'create_time'},
			{title:'最后登录时间',dataIndex:'login_time'},
			{title:'最后登录IP',dataIndex:'last_login_ip'},
			{title:'操作',dataIndex:'action', slots: { customRender: 'action' }},
		]" >
			<template #mobile="{record}">
				<span>{{ record.mobile }}@{{ master_name }}</span>
			</template>
			<template #name="{ record }">
				<span>{{ record.name }}@{{ master_name }}</span>
			</template>
			<template #status="{ record }">
				<a-tag :color="record.status == 1 ? '#00CC66':'#FF6633'">{{ record.status ? "正常":"已禁用" }}</a-tag>
			</template>
			<template #action="{ record }">
				<router-link :to="{ path: '/admin/employee/employee_info', query: { user_id: record.user_id } }">
					<a-button v-has="{ action: '/admin/employee/employee_info' }" type="link" >编辑</a-button>
				</router-link>
				<a-button v-if="record.status == 1" v-has="{ action: 'sass_employee_stop' }" type="link" @click="deletUser(record, 0)" size="small">禁用</a-button>
				<a-button v-else v-has="{ action: 'sass_employee_stop' }" type="link" @click="deletUser(record, 1)" size="small">启用</a-button>
			</template>
		</a-table>
		<div class="pager">
			<a-pagination 
				show-size-changer 
				v-model:current="info.page" 
				v-model:pageSize="info.limit" 
				:total="info.count" 
				@showSizeChange="(p,e)=>{getUserList(info.page,e)}"
				@change="(e)=>getUserList(e,info.limit)"
			/>
		</div>
		<a-modal v-model:visible="show.addOrEdit" title="添加/编辑员工" @ok="createOrUpdateAgent" @cancel="show.addOrEdit = false" width="500px">
			<a-form :label-col="{span:4}" :wrapper-col="{span:18}">
				<a-form-item label="用户名">
					<a-input v-model:value="form.name" :addon-after="'@'+$store.state.username"></a-input>
				</a-form-item>
				<a-form-item label="手机号">
					<a-input v-model:value="form.mobile" addon-before="+86"></a-input>
				</a-form-item>
				<a-form-item label="密码"><a-input v-model:value="form.password"></a-input></a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import userModel from '@/api/user';
import authModel from '@/api/saas/auth';
export default {
	setup(){
		const state = reactive({
			info: {
				list: [],
				page: 1,
				limit: 10,
				count: 0
			},
			name: '',
			myInfo: [],
			show: {
				addOrEdit: false
			},
			form: {
				name: '',
				mobile: '',
				password: ''
			},
			master_name: ''
		})

		userModel.getUserDetail("").then(res=>state.myInfo = res)
		getUserList(1,state.info.limit)

		function getUserList(page,limit){
			authModel.getEmployee(page,limit,{keyword:state.name},res=>{
				state.info.list = res.data;
				state.info.page = res.page.currentPage;
				state.info.count = res.page.totalCount;
				state.master_name = res.master_name;
			})
		}

		const deletUser = (e,status)=>authModel.changeEmployeeStatus(e.user_id,status,()=>{
			getUserList(state.info.page, state.info.limit)
		})

		const createOrUpdateAgent = ()=>authModel.addOrEditEmployee('add',state.form,()=>{
			getUserList(state.info.page, state.info.limit);
			state.show.addOrEdit = false
			state.form.name = ''
			state.form.mobile = ''
			state.form.password = ''
		})

		return{
			...toRefs(state),
			getUserList,
			createOrUpdateAgent,
			deletUser
		}
	}
}
</script>

<style lang="scss">
.user-search-input {
	width: 350px !important;
}
</style>
